<template>
  <LineChartGenerator
    :chart-options="options"
    :chart-data="chartData"
    chart-id="wettbewerb-report-overview-line-chart"
    :plugins="plugins"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import { mapGetters } from 'vuex';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  Filler,
} from 'chart.js';

import {
  CHART_OPTIONS,
  WETTBEWERB_PRODUCT_TYPES,
} from '../../../../utils/constants';

import fmhFinanzberatungPlugin from '../../../../plugins/fmhFinanzberatung';

ChartJS.register(
  Filler,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement
);

export default {
  name: 'ReportChart',
  components: { LineChartGenerator },
  props: {
    isShow: Object,
    form: Object,
    param: Object,
    data: Array,
    minWidth: Number,
    maxWidth: Number,
    minColor: String,
    maxColor: String,
    period: String,
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      permissions: 'auth/getPermissions',
    }),
    plugins() {
      return [fmhFinanzberatungPlugin];
    },
    isAvailable() {
      return (
        (this.$router.currentRoute.name !== 'rate-chart' &&
          !this.permissions.includes(
            WETTBEWERB_PRODUCT_TYPES[
              this.$router.currentRoute.params.producttype
            ]
          )) ||
        !!this.user?.isDemoUser
      );
    },
    options() {
      return {
        ...CHART_OPTIONS,
        plugins: {
          legend: { ...CHART_OPTIONS.plugins.legend },
          tooltip: this.isAvailable
            ? { enabled: false }
            : {
                caretSize: 0,
                mode: 'nearest',
                position: 'custom',
                yAlign: 'bottom',
                xAlign: 'center',
                titleMarginBottom: 3,
                callbacks: {
                  title: (context) => {
                    const value = Number(context?.[0]?.raw || 0)
                      .toFixed(2)
                      .replace('.', ',');
                    const label = context[0].label.replace(',', '.');
                    return `${label}: ${value} %`;
                  },
                  label: () => {
                    return null;
                  },
                },
              },
        },
        fmhFinanzberatung: {
          period: () => {
            return this.period;
          },
          id: 'wettbewerb-report-overview-line-chart',
        },
        onHover: (e, chartElement) => {
          const target = e.native ? e.native.target : e.target;
          if (this.isAvailable) {
            target.style.cursor = 'default';
          } else {
            target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          }
        },
      };
    },
    formParam() {
      return this.form;
    },
    chartParam() {
      return this.param;
    },
    hasMaxValue() {
      return this.param.chartMaxValue;
    },
    hasMinValue() {
      return this.param.chartMinValue;
    },
    hasAvgValue() {
      return this.param.chartAvgValue;
    },
    hasZinsValue1() {
      return this.param.zins1;
    },
    hasZinsValue2() {
      return this.param.zins2;
    },
    hasZinsValue3() {
      return this.param.zins3;
    },
    hasZinsValue4() {
      return this.param.zins4;
    },
    hasZinsValue5() {
      return this.param.zins5;
    },
    chartDataRaw() {
      return this.data;
    },
  },
  data() {
    return {
      chartData: { labels: [], datasets: [] },
    };
  },
  methods: {
    getChartColor: function (i) {
      return this.$store.getters['chartColors/getColor'](i).color;
    },
    loadChart() {
      if (!!this.chartDataRaw.length) {
        this.chartData.labels = [];
        this.chartData.datasets = [];

        for (let i = 0; i < this.chartDataRaw.length; i++) {
          const arr = [];
          const paths = this.$date(this.chartDataRaw[i].datum)
            .format('DD.MM.YYYY')
            .split('.');
          arr.push(`${paths[0]}.${paths[1]}`);
          arr.push(paths[2]);
          this.chartData.labels.push(arr);
        }
        if (this.hasMaxValue) {
          this.addDatasetMax();
        }
        if (this.hasAvgValue) {
          this.addDatasetAvg();
        }
        if (this.hasMinValue) {
          this.addDatasetMin();
        }
        if (this.hasZinsValue1) {
          if (this.isShow['zins1']) {
            this.addDatasetZins(
              this.formParam.bankname1,
              this.getChartColor(this.chartParam.datasetColor1),
              'zins1'
            );
          }
        }
        if (this.hasZinsValue2) {
          if (this.isShow['zins2']) {
            this.addDatasetZins(
              this.formParam.bankname2,
              this.getChartColor(this.chartParam.datasetColor2),
              'zins2'
            );
          }
        }
        if (this.hasZinsValue3) {
          if (this.isShow['zins3']) {
            this.addDatasetZins(
              this.formParam.bankname3,
              this.getChartColor(this.chartParam.datasetColor3),
              'zins3'
            );
          }
        }
        if (this.hasZinsValue4) {
          if (this.isShow['zins4']) {
            this.addDatasetZins(
              this.formParam.bankname4,
              this.getChartColor(this.chartParam.datasetColor4),
              'zins4'
            );
          }
        }
        if (this.hasZinsValue5) {
          if (this.isShow['zins5']) {
            this.addDatasetZins(
              this.formParam.bankname5,
              this.getChartColor(this.chartParam.datasetColor5),
              'zins5'
            );
          }
        }
      }
    },
    addDatasetMax() {
      const datasetMaxValue = {};
      datasetMaxValue.label = 'Maximum';
      datasetMaxValue.backgroundColor = this.hexToRGBA('#EBEBEB', 0.25);
      datasetMaxValue.borderColor = this.hexToRGB(this.maxColor);
      datasetMaxValue.borderWidth = this.maxWidth;
      datasetMaxValue.borderDash = [2, 6];
      datasetMaxValue.fill = true;
      datasetMaxValue.lineTension = 0;
      datasetMaxValue.pointBackgroundColor = 'transparent';
      datasetMaxValue.pointBorderColor = 'transparent';
      datasetMaxValue.pointHoverRadius = 5;
      datasetMaxValue.pointRadius = 5;
      datasetMaxValue.pointHoverBackgroundColor = this.isAvailable
        ? 'transparent'
        : '#fff';
      datasetMaxValue.pointHoverBorderColor = this.isAvailable
        ? 'transparent'
        : this.hexToRGB(this.maxColor);
      datasetMaxValue.data = [];

      for (let i = 0; i < this.chartDataRaw.length; i++) {
        datasetMaxValue.data.push(this.chartDataRaw[i].max);
      }

      this.chartData.datasets.push(datasetMaxValue);
    },
    addDatasetMin: function () {
      const datasetMinValue = {};
      datasetMinValue.label = 'Minimum';
      datasetMinValue.backgroundColor = 'transparent';
      datasetMinValue.borderColor = this.hexToRGB(this.minColor);
      datasetMinValue.borderWidth = this.minWidth;
      datasetMinValue.fill = true;
      datasetMinValue.lineTension = 0;
      datasetMinValue.pointBackgroundColor = 'transparent';
      datasetMinValue.pointBorderColor = 'transparent';
      datasetMinValue.pointHoverRadius = 5;
      datasetMinValue.pointRadius = 5;
      datasetMinValue.pointHoverBackgroundColor = this.isAvailable
        ? 'transparent'
        : '#fff';
      datasetMinValue.pointHoverBorderColor = this.isAvailable
        ? 'transparent'
        : this.hexToRGB(this.minColor);
      datasetMinValue.data = [];

      for (let i = 0; i < this.chartDataRaw.length; i++) {
        datasetMinValue.data.push(this.chartDataRaw[i].min);
      }

      this.chartData.datasets.push(datasetMinValue);
    },
    addDatasetAvg: function () {
      const datasetAvgValue = {};
      datasetAvgValue.label = 'Mittelwert';
      datasetAvgValue.backgroundColor = 'transparent';
      datasetAvgValue.borderColor = this.hexToRGB('#181818');
      datasetAvgValue.borderWidth = 1.5;
      datasetAvgValue.borderDash = [3, 3];
      datasetAvgValue.fill = true;
      datasetAvgValue.lineTension = 0;
      datasetAvgValue.pointBackgroundColor = 'transparent';
      datasetAvgValue.pointBorderColor = 'transparent';
      datasetAvgValue.pointHoverRadius = 5;
      datasetAvgValue.pointRadius = 5;
      datasetAvgValue.pointHoverBackgroundColor = this.isAvailable
        ? 'transparent'
        : '#fff';
      datasetAvgValue.pointHoverBorderColor = this.isAvailable
        ? 'transparent'
        : this.hexToRGB('#181818');
      datasetAvgValue.data = [];

      for (let i = 0; i < this.chartDataRaw.length; i++) {
        datasetAvgValue.data.push(this.chartDataRaw[i].mittelwert);
      }
      this.chartData.datasets.push(datasetAvgValue);
    },
    addDatasetZins(bankname, color, dataKey) {
      const dataset = {};
      dataset.label = bankname;

      if (dataKey === 'zins1') {
        dataset.borderWidth = 1;
        dataset.borderColor = this.hexToRGB(color);
        const canvas = document.getElementById(
          'wettbewerb-report-overview-line-chart'
        );
        const chartContext = canvas.getContext('2d');
        const gradientFill = chartContext.createLinearGradient(0, 0, 0, 400);
        gradientFill.addColorStop(0.2, this.hexToRGBA(color, 0.9));
        gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0.5)');
        gradientFill.addColorStop(1, this.hexToRGBA(color, 0.9));
        dataset.backgroundColor = gradientFill;
        dataset.order = 3;
      } else {
        dataset.order = 2;
        dataset.backgroundColor = 'transparent';
        dataset.borderColor = this.hexToRGB(color);
        dataset.borderWidth = 1.5;
      }
      dataset.fill = true;
      dataset.pointBackgroundColor = 'transparent';
      dataset.pointBorderColor = 'transparent';
      dataset.pointHoverRadius = 5;
      dataset.pointRadius = 5;
      dataset.pointHoverBackgroundColor = this.isAvailable
        ? 'transparent'
        : '#fff';
      dataset.pointHoverBorderColor = this.isAvailable
        ? 'transparent'
        : this.hexToRGB(color);
      dataset.lineTension = 0;
      dataset.data = [];

      for (let i = 0; i < this.chartDataRaw.length; i++) {
        dataset.data.push(this.chartDataRaw[i][dataKey]);
      }

      this.chartData.datasets.push(dataset);
    },
    updateChart() {
      this.loadChart();
    },
  },
  watch: {
    chartDataRaw() {
      this.updateChart();
    },
    hasMaxValue: function () {
      this.updateChart();
    },
    hasMinValue: function () {
      this.updateChart();
    },
    hasAvgValue: function () {
      this.updateChart();
    },
    hasZinsValue1: function () {
      this.updateChart();
    },
    hasZinsValue2: function () {
      this.updateChart();
    },
    hasZinsValue3: function () {
      this.updateChart();
    },
    hasZinsValue4: function () {
      this.updateChart();
    },
    hasZinsValue5: function () {
      this.updateChart();
    },
  },
  mounted() {
    this.updateChart();
  },
};
</script>

<style></style>
