








































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';

import LineChart from '@/components/fmh_components/common/LineChart/LineChart.vue';
import Loader from '@/components/fmh_components/common/Loader/Loader.vue';
import Error from '@/components/fmh_components/common/Error/Error.vue';
import ReportChart from '../../../wettbewerbsvergleich/src/components/Wettbewerb/common/Report/ReportChart.vue';

import { IBaseChart, IWettbewerbChart } from '@/interfaces';

import { COLOR, MONTHS, WETTBEWERB_CHART_OPTIONS } from '@/utils/constants';
import { checkedCheckBoxes } from '../../../wettbewerbsvergleich/src/utils/helpers';

@Component({
  components: {
    LineChart,
    Loader,
    Error,
    ReportChart,
  },
  computed: {
    ...mapGetters({
      loading: 'interestCharts/loading',
      error: 'interestCharts/error',
    }),
  },
  methods: {
    ...mapActions({
      fetchZinsChartData: 'interestCharts/fetchZinsChartData',
    }),
    ...mapMutations({
      setLoading: 'interestCharts/loading',
      setError: 'interestCharts/error',
    }),
  },
})
export default class RateChart extends Vue {
  color = COLOR;
  chartData: Array<IBaseChart> = [];
  wettbewerbChartData: Array<IWettbewerbChart> = [];
  months = MONTHS;
  params = {
    selectedSpecialValue: '',
    selectedSpecialSubValue: [],
    differentialValue: false,
    annotation: false,
    split: false,
    showLogo: true,
    von: `${new Date().getFullYear() - 10}-01`,
    bis: `${new Date().getFullYear()}-${this.months[new Date().getMonth()]}`,
    colors: {
      zins1: 2,
      zins2: 4,
      zins3: 5,
    },
    series: {
      zins1: {
        value: '',
        subValue: '',
        chartType: 'line',
      },
      zins2: {
        value: '',
        subValue: '',
        chartType: 'line',
      },
      zins3: {
        value: '',
        subValue: '',
        chartType: 'line',
      },
    },
    chartType: '',
  };
  wettbewerbParams = { page: '', chartParam: {}, formParam: {} };
  chartData1 = {
    id: 'line-chart-1',
    show: false,
    params: {},
    data: [],
  };
  chartData2 = {
    id: 'line-chart-2',
    show: false,
    params: {},
    data: [],
  };
  isWettbewerbChart = false;
  options = WETTBEWERB_CHART_OPTIONS;
  [x: string]: any;
  parsePeriod!: Function; //mixin

  fetchZinsChartData!: (payload) => Promise<any>;
  setLoading!: MutationMethod;
  setError!: MutationMethod;

  @Watch('$route', { immediate: true }) async setContent() {
    const decode = atob(String(this.$router.currentRoute.query.params));
    const slug = this.$router.currentRoute.params.slug;
    const data = JSON.parse(decode);
    try {
      const res = await this.fetchZinsChartData(slug);
      if (!data?.params?.page) {
        //from zinschart page
        this.params = data.params;
        this.chartData = res.data.data;
        this.updateData(res.data.data);
      } else {
        this.wettbewerbParams = data.params;
        this.wettbewerbChartData = res.data.data;
        this.isWettbewerbChart = true;
      }
    } catch (e) {
      this.setLoading(false);
      this.setError(true);
      console.log(e);
    }
    this.setLoading(false);
  }

  get description() {
    if (this.params?.selectedSpecialValue === 'rbGrafikAuswahl5') {
      const stringID = (this.params?.selectedSpecialSubValue || []).join('_');
      return this.$t(
        `chartGenerator.specialChartHousePriceDescription.${stringID}`
      );
    }
    return '';
  }
  get period() {
    return this.parsePeriod(this.wettbewerbParams.formParam, this.$date);
  }
  get isShow() {
    return checkedCheckBoxes({ data: this.wettbewerbChartData });
  }
  updateData(data) {
    if (this.params?.split && !this.params?.differentialValue) {
      const zins1 = data
        .filter((item) => item?.zins1)
        .map((item) => item?.zins1 || 0);
      const zins2 = data
        .filter((item) => item?.zins2)
        .map((item) => item?.zins2 || 0);
      const zins3 = data
        .filter((item) => item?.zins3)
        .map((item) => item?.zins3 || 0);
      const max1 = Math.max(...zins1);
      const max2 = Math.max(...zins2);
      const max3 = Math.max(...zins3);
      const arr = [max1, max2, max3].filter((item) => item);
      const o = {
        zins1: max1,
        zins2: max2,
        zins3: max3,
      };

      const searchMax = Object.keys(o).find(
        (key) => Math.max(...arr) === o[key]
      );
      const chartData1 = data.map((item) => {
        if (searchMax === 'zins1') {
          return {
            ...item,
            annotation: null,
            zins2: null,
            zins3: null,
          };
        }
        if (searchMax === 'zins2') {
          return {
            ...item,
            annotation: null,
            zins1: null,
            zins3: null,
          };
        }
        if (searchMax === 'zins3') {
          return {
            ...item,
            annotation: null,
            zins1: null,
            zins2: null,
          };
        }
      });
      const chartData2 = data.map((item) => {
        if (searchMax === 'zins1') {
          return {
            ...item,
            zins1: null,
          };
        }
        if (searchMax === 'zins2') {
          return {
            ...item,
            zins2: null,
          };
        }
        if (searchMax === 'zins3') {
          return {
            ...item,
            zins3: null,
          };
        }
      });

      this.chartData1 = {
        ...this.chartData1,
        show: true,
        params: this.params,
        data: chartData1 || [],
      };

      this.chartData2 = {
        ...this.chartData2,
        show: true,
        params: this.params,
        data: chartData2 || [],
      };
    }
    if (this.params?.differentialValue) {
      let minZins = '';
      data.forEach((item) => {
        const { zins1, zins2 } = item;
        if (Number(zins1) !== Number(zins2)) {
          const min = Math.min(...[Number(zins1), Number(zins2)]);
          const search = Object.keys(item).find((key) => item[key] === min);
          if (!minZins && search) {
            minZins = search;
          }
        }
      });
      if (minZins) {
        const chartDataDifference = data.map((item) => {
          const { zins1, zins2, ...rest } = item;
          if (minZins === 'zins1') {
            const result = (zins1 || 0) - (zins2 || 0);
            return {
              ...rest,
              zins1: result,
              annotation: null,
            };
          }
          if (minZins === 'zins2') {
            const result = (zins2 || 0) - (zins1 || 0);
            return {
              ...rest,
              zins1: result,
              annotation: null,
            };
          }
        });

        this.chartData1 = {
          ...this.chartData1,
          show: false,
          params: this.params,
          data: [],
        };
        //@ts-ignore
        //eslint-disable-next-line
        this.chartData2 = { ...this.chartData2, show: true, params: this.params, data: chartDataDifference || [] };
      } else {
        const chartDataDifference = this.chartData.map((item) => {
          const { zins1, zins2, ...rest } = item;
          return {
            ...rest,
            zins1,
            annotation: null,
          };
        });
        //@ts-ignore
        //eslint-disable-next-line
        this.chartData1 = { ...this.chartData1, show: false, params: this.params, data:  [] };
        //@ts-ignore
        //eslint-disable-next-line
        this.chartData2 = { ...this.chartData2, show: true, params: this.params, data: chartDataDifference || [] };
      }
    }

    if (!this.params?.split && !this.params?.differentialValue) {
      this.chartData1 = {
        id: 'line-chart-1',
        show: false,
        params: {},
        data: [],
      };
      this.chartData2 = {
        ...this.chartData2,
        show: !!data.length,
        params: this.params,
        data: data || [],
      };
    }
  }
  updateDataAnnotations(val) {
    this.chartData = this.chartData.map((item) => {
      if (typeof item?.annotation === 'number') {
        return { ...item, annotation: val };
      } else {
        return item;
      }
    });
    this.updateData(this.chartData);
  }
}
