var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"responsive-container",staticStyle:{"overflow":"auto"}},[_c('div',{staticClass:"loading"},[(_vm.loading)?_c('Loader'):_vm._e()],1),(_vm.error)?_c('Error'):_vm._e(),(_vm.chartData1.show && !_vm.isWettbewerbChart)?_c('div',{staticClass:"line-chart-container1"},[(_vm.params.showLogo)?_c('img',{attrs:{"src":"/assets/img/x-radar.svg","alt":"logo","width":"190","height":"190"}}):_vm._e(),_c('LineChart',{attrs:{"showXTicks":false,"padding":{
        left: 5,
        right: 40,
        top: 20,
        bottom: 0,
      },"data":_vm.chartData1,"chartId":_vm.chartData1.id},on:{"updateDataAnnotations":_vm.updateDataAnnotations}}),_c('p',{staticClass:"inter"},[_vm._v(_vm._s(_vm.description))])],1):_vm._e(),(_vm.chartData2.show && !_vm.isWettbewerbChart)?_c('div',{staticStyle:{"min-width":"700px"}},[_c('div',{staticClass:"line-chart-container2"},[(_vm.params.showLogo)?_c('img',{attrs:{"src":"/assets/img/x-radar.svg","alt":"logo","width":"190","height":"190"}}):_vm._e(),_c('LineChart',{staticClass:"chart2",attrs:{"showXTicks":"","padding":{
          left: 5,
          right: 40,
          top: 40,
          bottom: 20,
        },"data":_vm.chartData2,"chartId":_vm.chartData2.id},on:{"updateDataAnnotations":_vm.updateDataAnnotations}})],1),_c('p',{staticClass:"inter"},[_vm._v(_vm._s(_vm.description))])]):_vm._e(),(_vm.isWettbewerbChart)?_c('div',{staticStyle:{"min-width":"700px"}},[_c('ReportChart',{attrs:{"isShow":_vm.isShow,"param":_vm.wettbewerbParams.chartParam,"form":_vm.wettbewerbParams.formParam,"data":_vm.wettbewerbChartData,"minWidth":_vm.options[_vm.wettbewerbParams.page].minWidth,"maxWidth":_vm.options[_vm.wettbewerbParams.page].maxWidth,"minColor":_vm.options[_vm.wettbewerbParams.page].minColor,"maxColor":_vm.options[_vm.wettbewerbParams.page].maxColor,"period":_vm.period}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }